<template>
  <div id="business">
    <cent-title title="客户信息列表"></cent-title>
    <!-- <div class="user">
      <div class="Bitem">
        <div>姓名：</div>
        <el-input
          v-model.trim="username"
          placeholder="客户姓名"
          class="miniInput"
          maxlength="20"
          clearable
        ></el-input>
      </div>
      <div class="Bitem">
        <div>地区：</div>
        <el-input
          v-model.trim="location"
          placeholder="请选择地区"
          class="miniInput"
          maxlength="20"
          clearable
        ></el-input>
      </div>
      <div class="Bitem">
        <div>编号：</div>
        <el-input
          v-model.trim="userNum"
          placeholder="客户编号"
          class="miniInput"
          maxlength="20"
          clearable
        ></el-input>
      </div>
      <div class="Bitem">
        <div>等级：</div>
        <el-select
          v-model="vipLevel"
          @change="changeGrade"
          placeholder="会员等级"
          class="miniInput"
          clearable
        >
          <el-option
            v-for="item in vipLevelList"
            :key="item.code"
            :label="item.value"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </div>
      <div class="Bitem">
        <div>祖籍：</div>
        <el-input
          v-model.trim="hometown"
          placeholder="祖籍"
          class="miniInput"
          maxlength="20"
          clearable
        ></el-input>
      </div>
      <div class="Bitem">
        <div>跟进状态：</div>
        <el-select
          v-model="follow"
          @change="changeFollow"
          placeholder="请选择跟进状态"
          class="miniInput"
          clearable
        >
          <el-option
            v-for="item in followList"
            :key="item.code"
            :label="item.value"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </div>
      <div class="Bitem">
        <div>跟进时间：</div>
        <el-date-picker
          class="miniInput"
          v-model="followDate"
          format="yyyy - MM - dd"
          value-format="yyyy/MM/dd"
          :picker-options="{ firstDayOfWeek: 1, disabledDate }"
          type="date"
          placeholder="选择日期"
          clearable
        >
        </el-date-picker>
      </div>
      <div class="Bitem">
        <div>年龄：</div>
        <el-input
          v-model.trim="startAge"
          placeholder="起"
          maxlength="3"
          class="minisInput"
          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
          clearable
        ></el-input>
        <div style="margin: 0 6px; color: #ccc">--</div>
        <el-input
          v-model.trim="endAge"
          placeholder="始"
          class="minisInput minisInput2"
          maxlength="3"
          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
          clearable
        ></el-input>
      </div>
      <div class="Bitem">
        <div>身高：</div>
        <el-input
          v-model.trim="startHeight"
          placeholder="起"
          maxlength="3"
          class="minisInput"
          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
          clearable
        ></el-input>
        <div style="margin: 0 6px; color: #ccc">--</div>
        <el-input
          v-model.trim="endHeight"
          placeholder="始"
          class="minisInput minisInput2"
          maxlength="3"
          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
          clearable
        ></el-input>
      </div>
      <div class="Bitem">
        <div>收入：</div>
        <el-input
          v-model.trim="startSalary"
          placeholder="起"
          maxlength="3"
          class="minisInput"
          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
          clearable
        ></el-input>
        <div style="margin: 0 6px; color: #ccc">--</div>
        <el-input
          v-model.trim="endSalary"
          placeholder="始"
          class="minisInput minisInput2"
          maxlength="3"
          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
          clearable
        ></el-input>
      </div>
      <div class="Bitem">
        <div>性别：</div>
        <el-select
          v-model="gender"
          @change="changeGender"
          placeholder="请选择客户性别"
          class="miniInput"
          clearable
        >
          <el-option
            v-for="item in genderList"
            :key="item.code"
            :label="item.value"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </div>
      <div class="Bitem">
        <div>是否发广告：</div>
        <el-select
          v-model="demand"
          @change="changeDemand"
          placeholder="请选择"
          class="miniInput"
          clearable
        >
          <el-option
            v-for="item in demandList"
            :key="item.code"
            :label="item.value"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </div>
      <div class="Bitem">
        <div>负责人：</div>
        <el-select
          v-model="principal"
          @change="changePrincipal"
          placeholder="请选择负责人"
          class="miniInput"
          clearable
        >
          <el-option
            v-for="item in principalList"
            :key="item.uid"
            :label="item.username"
            :value="item.uid"
          >
          </el-option>
        </el-select>
        <el-button size="mini" icon="el-icon-search" @click="queryData"
          >查询</el-button
        >
      </div>
      <div class="Bitem">
        <el-button
          size="mini"
          icon="el-icon-plus"
          class="add"
          @click="addUserInfo"
          >新增</el-button
        >
      </div>
      <div class="Bitem">
        <el-button
          size="mini"
          icon="el-icon-refresh"
          class="reset"
          @click="clearInfo"
          >重置</el-button
        >
      </div>
    </div> -->
    <el-table :data="userList" border style="width: 100%">
      <el-table-column
        type="index"
        label="序号"
        width="60"
        align="center"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column label="相亲卡" width="80" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.card != ''">
            <el-image
              :src="scope.row.card[0].cardUrl"
              :preview-src-list="previewList"
              @click="getCardUrl(scope.row.card)"
              lazy
              style="width: 51px; height: 48px"
            />
          </div>
          <div v-else>
            <el-image
              :src="require('../../assets/img/business/error.png')"
              lazy
              style="width: 51px; height: 48px"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="个人照片" width="80" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.photo != ''">
            <el-image
              :src="scope.row.photo[0].photoUrl"
              :preview-src-list="previewList"
              @click="getPhotoUrl(scope.row.photo)"
              lazy
              style="width: 51px; height: 48px"
            />
          </div>
          <div v-else>
            <el-image
              :src="require('../../assets/img/business/error.png')"
              lazy
              style="width: 51px; height: 48px"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="username"
        label="客户姓名"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="userNum"
        label="客户编号"
        width="90"
        align="center"
      ></el-table-column>
      <el-table-column label="审核状态" width="90" align="center">
        <template slot-scope="scope">
          <div class="with past" v-if="scope.row.checkStatus === 0">待审核</div>
          <div class="with pass" v-else-if="scope.row.checkStatus === 1">
            已通过
          </div>
          <div class="with notPass" v-else-if="scope.row.checkStatus === -1">
            未通过
          </div>
          <div class="with anomaly" v-else-if="scope.row.checkStatus === 100">
            数据异常
          </div>
        </template>
      </el-table-column>
      <el-table-column label="数据来源" width="80" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.dataSource === 0">小程序</div>
          <div v-else-if="scope.row.dataSource === 1">公众号</div>
        </template>
      </el-table-column>
      <el-table-column label="会员状态" width="80" align="center">
        <template slot-scope="scope">
          <div class="with past" v-if="scope.row.vipStatus === -1">过期</div>
          <div class="with not" v-else-if="scope.row.vipStatus === 0">无</div>
          <div class="with pass" v-else-if="scope.row.vipStatus === 1">
            在期
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="demand"
        label="客户要求"
        width="140"
        align="center"
      >
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.demand"
            @change="updateDemand(scope.row)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in demandList"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="会员等级" width="125" align="center">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.vipLevel"
            @change="updatevipLevel(scope.row)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in vipLevelList"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="跟进状态" width="125" align="center">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.follow"
            @change="updateFollow(scope.row)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in followList"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        prop="principal"
        label="负责人"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.principal"
            @change="updatePrincipal(scope.row)"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in principalList"
              :key="item.uid"
              :label="item.username"
              :value="item.username"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        prop="updateTime"
        label="修改时间"
        width="160"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="客户备注"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-popover
            placement="top-start"
            title="客户备注"
            width="200"
            trigger="hover"
            :content="scope.row.remark"
          >
            <div slot="reference">{{ scope.row.remark }}</div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" width="225" align="center">
        <template slot-scope="scope">
          <el-button
            class="andStyle update"
            icon="el-icon-edit"
            @click="extensionEdit(scope.row, scope.$index)"
            >编辑</el-button
          >
          <el-button
            class="andStyle uploading"
            icon="el-icon-plus"
            @click="extensionUpload(scope.row)"
            >上传图片</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="pageSize"
      prev-text="上一页"
      next-text="下一页"
      layout="total, prev, pager, next,jumper,slot"
      :total="total"
    >
    </el-pagination>
    <!-- 修改信息  = false-->
    <my-amend v-show="isAmend" @btnClose="isAmend2">
      <user-card
        ref="refs"
        :ruleForm="ruleForm"
        :state_1="state_1"
        :state_2="state_2"
        @succeddEdit="succeddEdit"
        :disabledShow="disabledShow"
      ></user-card>
    </my-amend>
    <!-- 上传图片 -->
    <my-amend v-show="isUpload" @btnClose="isUpload = false">
      <div class="isTitle">补充信息</div>
      <div class="content">
        <div class="leftText">个人照片：</div>
        <div
          class="upPhotoList"
          v-for="(item, index) in photoTemporaryInfo.photo"
          :key="item.id"
          @dragstart="handleDragStart(item)"
          @dragover.prevent="handleDragOver($event)"
          @dragenter="handleDragEnter(index)"
          @dragend="handleDragEnd(item, index)"
        >
          <el-image
            class="avatar"
            v-if="item.isDeleted === 0"
            :src="item.photoUrl"
            :preview-src-list="[item.photoUrl]"
          />
          <div class="close" @click="deletePhoto(item, index)">
            <img
              class="close_icon"
              :src="require('../../assets/img/del/close_icon.png')"
            />
          </div>
        </div>
        <el-upload
          class="avatar-uploader"
          action=""
          :multiple="true"
          :auto-upload="false"
          :on-change="onPhotoChange"
          list-type="picture"
          :show-file-list="false"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
      <div class="content">
        <div class="leftText">个人视频：</div>
        <div
          class="upPhotoList"
          v-for="(item, index) in photoTemporaryInfo.video"
          :key="item.id"
        >
          <video
            class="video"
            v-if="item.isDeleted === 0"
            :src="item.video"
            controls="controls"
          />
          <div class="close" @click="deleteVideo(item, index)">
            <img
              class="close_icon"
              :src="require('../../assets/img/del/close_icon.png')"
            />
          </div>
        </div>
        <el-upload
          class="avatar-uploader"
          action=""
          :auto-upload="false"
          :on-change="onVideoChange"
          list-type="picture"
          :show-file-list="false"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
      <div class="content">
        <div class="leftText">相亲卡：</div>
        <div v-if="photoTemporaryInfo.card != ''" class="cardList">
          <div
            class="upPhotoList"
            v-for="(item, index) in photoTemporaryInfo.card"
            :key="index"
          >
            <el-image
              class="avatar"
              v-if="item.isDeleted === 0"
              :src="item.cardUrl"
              :preview-src-list="[item.cardUrl]"
            />
          </div>
        </div>
        <div v-else>
          <el-image
            :src="require('../../assets/img/business/error.png')"
            lazy
            class="avatar"
          />
        </div>
      </div>
      <div class="btn">
        <el-button class="affirm" @click="submitAffirm">生成相亲卡</el-button>
      </div>
    </my-amend>
  </div>
</template>

<script>
import centTitle from "../content/centTitle.vue";
import myAmend from "../case/amend.vue";
import UserCard from "../../views/business/child/userCard.vue";

import { request } from "../../API/request";
import { debounce, throttle } from "../code/common";

export default {
  name: "business",
  components: {
    centTitle,
    myAmend,
    UserCard,
  },
  props: {
    chooseType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      state_1: '',
      state_2: '',
      handlers: JSON.parse(localStorage.getItem("userInfo")),
      username: "", // 客户姓名
      location: "", // 客户地址
      userNum: "", // 客户编号
      follow: "", // 跟进状态
      hometown: "", // 祖籍
      followDate: "", // 跟进时间
      startAge: "", // 输入起年龄
      endAge: "", // 输入始年龄
      startHeight: "", // 输入起身高
      endHeight: "", // 输入始身高
      startSalary: "", // 输入起年收入
      endSalary: "", // 输入始年收入
      demand: "", // 客户要求
      genderList: [
        { code: 0, value: "女" },
        { code: 1, value: "男" },
      ],
      gender: "", // 客户性别
      demandList: [
        { code: 1, value: "发广告" },
        { code: 2, value: "不发广告" },
        { code: 3, value: "只发朋友圈" },
        { code: 4, value: "只发征婚网" }
      ],
      principal: "", // 负责人
      principalData: "", // 勾选的负责人数据
      principalList: [], // 负责人列表
      followList: [
        { code: 1, value: "观望" },
        { code: 2, value: "有意向" },
        { code: 3, value: "犹豫中" },
        { code: 4, value: "待付费" },
        { code: 5, value: "成交" },
        { code: 6, value: "二次消费" },
        { code: 7, value: "退出" },
        { code: 8, value: "待核实" },
      ], // 跟进状态列表
      vipLevel: "", // 会员等级
      vipLevelList: [
        { code: 0, value: "普通客户" },
        { code: 1, value: "全球单线" },
        { code: 10, value: "全球三个月" },
        { code: 100, value: "全球八个月" },
        { code: 1000, value: "全球永久" },
        { code: 2, value: "同国单线" },
        { code: 20, value: "同国三个月" },
        { code: 200, value: "同国八个月" },
        { code: 2000, value: "同国永久" },
        { code: 3, value: "同城单线" },
        { code: 30, value: "同城三个月" },
        { code: 300, value: "同城八个月" },
        { code: 3000, value: "同城永久" },
        { code: 4, value: "日本单线" },
        { code: 40, value: "日本三个月" },
        { code: 400, value: "日本八个月" },
        { code: 4000, value: "日本永久" }
      ], // 会员等级选项

      // vipLevelList: [
      //   { code: 0, value: "普通客户" },
      //   { code: 1, value: "普卡VIP" },
      //   { code: 10, value: "金卡VIP" },
      //   { code: 100, value: "铂金VIP" },
      // ], // 会员等级选项

      userList: [], // 表格数据
      page: 1, // 当前页数
      pageSize: 8, // 每页条数
      total: 0, // 总条数
      isCase: false, // 删除弹框组件
      isAmend: false, // 修改内容组件
      isUpload: false, // 上传图片弹框
      previewList: [1], // 图片预览图

      ruleForm: {}, // 修改弹框
      edit: false, // 弹框属性默认为添加0
      editIndex: 0, // 编辑的列表索引

      photoTemporaryInfo: {}, // 上传图片临时信息
      // 个人图片的临时列表
      upPhotoList: [],
      dragging: null, // 拖拽目标
      draggingEndIndex: 0, //
      disabledShow: false,
    };
  },
  created() {
    this.getBusinessList();
    request({
      url: "/user/getMember",
      method: "GET",
    }).then((res) => {
      if (res.code === 200) {
        this.principalList = res.data;
      }
    });
  },
  methods: {
    isAmend2() {
      this.isAmend = false;
      this.$refs.refs.disabled = true;
    },
    // 获取客户信息列表
    getBusinessList() {
      request({
        url: "/customer/selectCustomer",
        method: "GET",
        params: {
          page: this.page,
          size: this.pageSize,
          username: this.username,
          location: this.location,
          userNum: this.userNum,
          vipLevel: this.vipLevel,
          follow: this.follow,
          hometown: this.hometown,
          followDate: this.followDate,
          startAge: this.startAge,
          endAge: this.endAge,
          startHeight: this.startHeight,
          endHeight: this.endHeight,
          startSalary: this.startSalary,
          endSalary: this.endSalary,
          demand: this.demand,
          gender: this.gender,
          uid: this.principal,
          chooseType: this.chooseType
        },
      }).then((res) => {
        if (res.code === 200) {
          this.page = res.data.current;
          this.total = res.data.total;
          let records = res.data.records
          console.log(records);
          records.map(item => {
            item.birthday = this.timestampToTime(item.birthday)
          })
          this.userList = records
          console.log('观望table', this.userList);
          this.$message.success("查询的数据成功！");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    timestampToTime(timestamp) {
      if (timestamp === 0 || timestamp == null) {
        return ''
      } else {
        let date = new Date(timestamp)
        let Y = date.getFullYear() + '-'
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        return Y + M + D
      }
    },
    // 改变页数对应序号
    indexMethod(index) {
      return (this.page - 1) * this.pageSize + index + 1;
    },




    // 会员等级选框
    changeGrade(e) {
      this.vipLevel = e;
    },
    // 跟进状态
    changeFollow(e) {
      this.follow = e;
    },
    // 限制选择日期
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    // 用户性别
    changeGender(e) {
      this.gender = e;
    },
    // 是否发广告
    changeDemand(e) {
      this.demand = e;
    },
    // 负责人
    changePrincipal(e) {
      let list = this.principalList;
      for (let i = 0; i < list.length; i++) {
        if (list[i].uid === e) {
          this.principalData = list[i];
        }
      }
    },
    // 查询用户数据
    queryData: debounce(function () {
      this.getBusinessList();
    }, 300),
    // 重置选框中的内容
    clearInfo: debounce(function () {
      [
        this.username,
        this.location,
        this.userNum,
        this.follow,
        this.hometown,
        this.followDate,
        this.startAge,
        this.endAge,
        this.startHeight,
        this.endHeight,
        this.startSalary,
        this.endSalary,
        this.demand,
        this.principal,
        this.username,
        this.gender,
        this.vipLevel,
      ] = [""];

      this.page = 1;
      this.getBusinessList();
    }, 300),
    // 新增客户信息
    addUserInfo() {
      console.log('新增客户信息');
      this.disabledShow = false;
      this.ruleForm = {
        username: "",
        userNum: "",
        gender: 0,
        age: "",
        birthday: "",
        education: "",
        job: "",
        nationality: "",
        height: "",
        weight: "",
        maritalStatus: "",
        hobby: "",
        demand: "",
        hopes: "",
        hometown: "",
        characters: "",
        emotionalExp: "",
        growthBackground: "",
        workingCondition: "",
        location: "",
        card: [],
        photo: [],
        vipLevel: 0,
        vipStatus: 0,
        dataSource: 0,
        ps: "",
        remark: "",
        handler: this.handlers.username,
        checkStatus: 0,
        hSecret: 0,
        wSecret: 0,
        sSecret: 0,
        state:""
      };
      this.edit = false;
      this.isAmend = true;
    },
    // 显示相亲卡预览图
    getCardUrl(item) {
      let list = [];
      for (let i = 0; i < item.length; i++) {
        list.push(item[i].cardUrl);
      }
      this.previewList = list;
    },
    // 显示个人照片预览图
    getPhotoUrl(item) {
      let list = [];
      for (let i = 0; i < item.length; i++) {
        list.push(item[i].photoUrl);
      }
      this.previewList = list;
    },
    // 更改客户要求
    updateDemand(row) {
      let that = this;
      that
        .$confirm("此操作将会更改用户等级, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        })
        .then(() => {
          if (row.userNum != null) {
            request({
              url: "/customer/demand",
              method: "GET",
              params: {
                id: row.id,
                demand: row.demand,
              },
            }).then((res) => {
              if (res.code === 200) {
                that.$message.success("操作修改客户要求成功！");
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            that.$message.error("请先完善客户信息！");
          }
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 更改用户的会员等级
    updatevipLevel(row) {
      let that = this;
      that
        .$confirm("此操作将会更改用户等级, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        })
        .then(() => {
          if (row.userNum != null) {
            request({
              url: "/vip/addVip",
              method: "POST",
              data: {
                cid: row.id,
                userNum: row.userNum,
                vipLevel: row.vipLevel,
              },
            }).then((res) => {
              if (res.code === 200) {
                that.$message.success("操作修改会员等级成功！");
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            that.$message.error("请先完善客户信息！");
          }
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 更改跟进状态
    updateFollow(e) {
      let that = this;
      that
        .$confirm("此操作会修改用户跟进状态, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        })
        .then(() => {
          request({
            url: "/customer/editFollow",
            method: "GET",
            params: {
              follow: e.follow,
              id: e.id,
            },
          }).then((res) => {
            if (res.code === 200) {
              that.$message.success("操作修改用户跟进状态成功！");
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 更改负责人
    updatePrincipal(e) {
      let that = this;
      that
        .$confirm("此操作会修改该客户负责人, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        })
        .then(() => {
          let data = "";
          let list = that.principalList;
          for (let i = 0; i < list.length; i++) {
            if (list[i].username === e.principal) {
              data = list[i];
            }
          }
          setTimeout(() => {
            request({
              url: "/customer/editPrincipal",
              method: "POST",
              data: {
                cid: e.id,
                uid: data.uid,
                principal: data.username,
              },
            }).then((res) => {
              if (res.code === 200) {
                that.$message.success("操作修改用户跟进状态成功！");
              } else {
                this.$message.error(res.message);
              }
            });
          }, 10);
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 修改用户信息
    extensionEdit(row, index) {
      console.log(row,'row2222');
      this.disabledShow = true;
      this.edit = true;
      this.editIndex = index;
      this.ruleForm = JSON.parse(JSON.stringify(row));
      this.state_1 = row.state.slice(0, 2)
      this.state_2 = row.state.slice(2)
      console.log(this.state_1, this.state_2);
      this.isAmend = true;
    },
    // 确认修改信息回调
    succeddEdit: debounce(function (e) {
      let data = {
        id: e.id,
        username: e.username,
        userNum: e.userNum,
        gender: e.gender,
        age: e.age,
        birthday: e.birthday,
        job: e.job,
        nationality: e.nationality,
        education: e.education,
        height: e.height,
        weight: e.weight,
        salary: e.salary,
        maritalStatus: e.maritalStatus,
        hobby: e.hobby,
        demand: e.demand,
        hopes: e.hopes,
        hometown: e.hometown,
        characters: e.characters,
        emotionalExp: e.emotionalExp,
        growthBackground: e.growthBackground,
        workingCondition: e.workingCondition,
        location: e.location,
        card: e.card,
        photo: e.photo,
        dataSource: e.dataSource,
        ps: e.ps,
        remark: e.remark,
        handler: this.handlers.username,
        checkStatus: e.checkStatus,
        hSecret: e.hSecret,
        wSecret: e.wSecret,
        sSecret: e.sSecret,
        profession: e.profession,
        state: e.state,
      };
      if (!this.edit) {
        delete data.id;
        request({
          url: "/customer/add",
          method: "POST",
          data,
        }).then((res) => {
          console.log("添加");
          if (res.code === 200) {
            this.isAmend = false;
            this.page = 1;
            this.$message.success(res.message);
            this.getBusinessList();
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        request({
          url: "/customer/edit",
          method: "POST",
          data,
        }).then((res) => {
          console.log("编辑");
          if (res.code === 200) {
            let list = this.userList;
            list.splice(this.editIndex, 1, res.data);
            this.userList = list;
            this.$message.success("修改操作成功！");
            this.isAmend = false;
          } else {
            this.$message.error(res.message);
          }
        });
      }
    }, 300),
    // 点击上传图片按钮
    extensionUpload(row) {
      console.log(this.photoTemporaryInfo);
      this.photoTemporaryInfo = row;
      console.log(this.photoTemporaryInfo, 88888);
      this.isUpload = true;
      // 重置临时图片列表
      this.upPhotoList = [];
    },
    // 添加个人照片
    onPhotoChange(file) {
      let type = file.raw.type;
      if (this.photoTemporaryInfo.photo.length + 1 <= 8) {
        if (
          (type.indexOf("/png") != -1 ||
            type.indexOf("/jpg") != -1 ||
            type.indexOf("/jpeg") != -1) &&
          file.size / (1024 * 1024) <= 20
        ) {
          this.upPhotoList.push(file.raw);
          let data = {
            photoUrl: file.url,
            isDeleted: 0,
            uid: file.uid,
            temporaryPhoto: true,
          };
          this.photoTemporaryInfo.photo.push(data);
        } else {
          this.$message.error("文件格式错误或文件过大！");
        }
      } else {
        this.$message.error("上传的个人照片不能超过八张！");
      }
    },
    // 删除个人照片
    deletePhoto(item, index) {
      let that = this;
      that
        .$confirm("此操作将会删除用户照片, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        })
        .then(() => {
          if (item.temporaryPhoto === true) {
            for (let i = 0; i < this.upPhotoList.length; i++) {
              if (item.uid === this.upPhotoList[i].uid) {
                this.upPhotoList.splice(i, 1);
              }
              this.photoTemporaryInfo.photo.splice(index, 1);
            }
          } else {
            if (this.photoTemporaryInfo.photo.length > 2) {
              request({
                url: "/customer/delUrl",
                method: "GET",
                params: {
                  id: item.id,
                },
              }).then((res) => {
                if (res.code === 200) {
                  this.photoTemporaryInfo.photo.splice(index, 1);
                  this.$message.success("操作删除图片成功！");
                } else {
                  this.$message.error(res.message);
                }
              });
            } else {
              this.$message.warning("客户图片不能少于两张！");
            }
          }
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 添加视频
    onVideoChange(file) {
      if (this.photoTemporaryInfo.video.length <= 1) {
        if (
          (file.name.indexOf(".mp4") != -1 ||
            file.name.indexOf(".MP4") != -1 ||
            file.name.indexOf(".MPEG") != -1 ||
            file.name.indexOf(".AVI") != -1 ||
            file.name.indexOf(".nAVI") != -1 ||
            file.name.indexOf(".ASF") != -1 ||
            file.name.indexOf(".MOV") != -1 ||
            file.name.indexOf(".3GP") != -1 ||
            file.name.indexOf(".WMV") != -1 ||
            file.name.indexOf(".DivX") != -1 ||
            file.name.indexOf(".RM") != -1 ||
            file.name.indexOf(".RMVB") != -1 ||
            file.name.indexOf(".XviD") != -1) &&
          file.size / (1024 * 1024) <= 30
        ) {
          const loading = this.$loading({
            lock: true,
            text: "拼命上传中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });

          let form = new FormData();
          form.append("files", file.raw);
          form.append("cid", this.photoTemporaryInfo.id);
          form.append("vipLevel", this.photoTemporaryInfo.vipLevel);
          form.append("userNum", this.photoTemporaryInfo.userNum);
          request({
            url: "/customer/addVideo",
            method: "POST",
            data: form,
          }).then((res) => {
            if (res.code === 200) {
              this.photoTemporaryInfo.video = res.data;
              loading.close();
            } else {
              loading.close();
              this.$message.error(res.message);
            }
          });
        } else {
          this.$message.error("文件格式错误或文件过大！");
        }
      } else {
        this.$message.error("上传的个人视频不能超过两个！");
      }
    },
    // 删除视频
    deleteVideo(item, index) {
      let that = this;
      that
        .$confirm("此操作将会删除用户视频, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        })
        .then(() => {
          request({
            url: "/customer/delUrl",
            method: "GET",
            params: {
              id: item.id,
            },
          }).then((res) => {
            if (res.code === 200) {
              this.photoTemporaryInfo.video.splice(index, 1);
              this.$message.success("操作删除视频成功！");
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 提交生成相亲卡
    submitAffirm: debounce(function () {
      let that = this;
      let upPhoto = that.upPhotoList;
      console.log(upPhoto)
      let formList = JSON.parse(JSON.stringify(that.photoTemporaryInfo));
      console.log(formList);
      if (upPhoto.length + that.photoTemporaryInfo.photo.length > 1) {
        const loading = that.$loading({
          lock: true,
          text: "拼命上传中",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        if (
          formList.username != null &&
          formList.userNum != null &&
          formList.gender != null &&
          formList.age != null &&
          formList.job != null &&
          formList.age != null &&
          formList.education != null &&
          formList.nationality != null &&
          formList.height != null &&
          formList.weight != null &&
          formList.salary != null &&
          formList.maritalStatus != null &&
          formList.hobby != null &&
          formList.emotionalExp != null &&
          formList.location != null
        ) {
          if (upPhoto.length > 0) {
            let form = new FormData();
            for (let i = 0; i < that.upPhotoList.length; i++) {
              form.append("files", that.upPhotoList[i]);
            }
            form.append("cid", that.photoTemporaryInfo.id);
            form.append("vipLevel", that.photoTemporaryInfo.vipLevel);
            form.append("userNum", that.photoTemporaryInfo.userNum);
            request({
              url: "/customer/uploadPhoto",
              method: "POST",
              data: form,
            }).then((res) => {
              if (res.code === 200) {
                let info = res.data.customerInfo;
                info.photo = res.data.photoInfo;
                that.$router.push({
                  name: "canvasCard",
                  params: {
                    form: info,
                  },
                });
                loading.close();
              } else {
                loading.close();
                this.$message.error(res.message);
              }
            });
          } else {
            loading.close();
            that.$router.push({
              name: "canvasCard",
              params: {
                form: formList,
              },
            });
          }
        } else {
          that.$message.error("请先完善用户信息！");
        }
      } else {
        that.$message.error("生成相亲卡的个人图片不能少于两张！");
      }
    }, 300),

    // 页数切换
    handleCurrentChange(val) {
      this.page = val;
      this.getBusinessList();
    },
    handleDragStart(item) {
      this.dragging = item;
    },
    // 拖拽结束
    handleDragEnd(item, index) {
      this.photoTemporaryInfo.photo.splice(index, 1);
      this.photoTemporaryInfo.photo.splice(this.draggingEndIndex, 0, item);
      this.dragging = null;
    },
    //首先把div变成可以放置的元素，即重写dragenter/dragover
    handleDragOver(e) {
      e.dataTransfer.dropEffect = "move"; // e.dataTransfer.dropEffect="move";//在dragenter中针对放置目标来设置!
    },
    handleDragEnter(index) {
      this.draggingEndIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
#business {
  width: 100%;
  height: 100%;
  .user {
    margin: 12px 0;
    padding: 12px 0 0 24px;
    background: var(--gray);
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    .Bitem {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }
    .miniInput {
      width: 120px;
      margin-right: 23px;
    }
    .minisInput {
      width: 70px;
    }
    .minisInput2 {
      margin-right: 23px;
    }
    .el-button {
      background: var(--subPink);
      color: var(--white);
      font-size: 16px;
      padding: 10px 20px;
      margin: 0 23px 0 6px;
    }
    .add {
      background: var(--blue);
    }
    .reset {
      background: var(--info);
    }
  }
  .andStyle {
    padding: 10px 16px;
    font-size: 14px;
    color: var(--white);
  }

  .update {
    background: var(--green);
  }
  .uploading {
    background: var(--blue);
  }
  .delete {
    background: var(--info);
  }
  .el-table {
    margin: 6px 0 6px;
    /deep/.cell {
      // overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
      -webkit-line-clamp: 1; /* 控制最多显示几行 */
      -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
    }
    .with {
      padding: 8px 0;
      color: var(--white);
      border-radius: 3px;
    }
    .pass {
      background: var(--green);
    }
    .notPass {
      background: var(--pink);
    }
    .past {
      background: var(--info);
    }
    .anomaly {
      background: var(--red);
    }
    .not {
      background: var(--not);
    }
  }
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
  }
  .isTitle {
    font-size: var(--mainSize);
    text-align: center;
    margin-bottom: 12px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 12px;
    font-size: var(--subSize);
    .leftText {
      width: 100px;
    }
    .upPhotoList {
      position: relative;
      margin: 20px 20px 0 0;
      .close {
        position: absolute;
        top: -12px;
        right: -12px;
        .close_icon {
          width: 24px;
          height: 24px;
        }
      }
    }
    .cardList {
      display: flex;
      flex-wrap: wrap;
    }
    .video {
      width: 180px;
      height: 300px;
      display: block;
    }
    .avatar-uploader {
      margin-top: 20px;
    }
    .el-upload {
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      display: flex;
      &:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        border: 1px dashed #999999;
        font-size: 28px;
        color: #999999;
        width: 105px;
        height: 105px;
        line-height: 105px;
        text-align: center;
      }
    }
  }
  .avatar {
    width: 105px;
    height: 105px;
    display: block;
  }
  .btn {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: center;
    .affirm {
      background: var(--pink);
      color: var(--white);
    }
  }
}
</style>